import React, { useEffect, useState } from 'react';
import data from "../../JsonData/data.json";
import SweetAlert from 'react-bootstrap-sweetalert';

const User = (props) => {
    const [alert, setAlert] = useState(false);
    const [createUserData, setCreateUserData] = useState([]);
    const [renderState, setRenderState] = useState(false);
    const sectiondata = props?.sectiondata;//total json
    const copiedJson = sectiondata;

    const toggleData = data?.roledata[0].toggles;//toggle data
    const userData = copiedJson;//section data
    const roleVal = props?.roleName;//selected role name
    // console.log(roleVal);
    // console.log("userdata", userData)

    // const sectionKeys = Object.keys(sectionData);

    const columns = [];
    let column, column2;

    const trimString = (str) => {

        return str?.replace(/\s+/g, '').trim();
    };


    const renderElements = () => {
        for (let i = 0; i < userData?.length; i += 8) {
            column = userData.slice(i, i + 8).map((element, index) => element?.type != "footermenu" && (
                <div key={index}>
                    <div className="con">
                        {element.menu}
                    </div>
                    {element.submenus && element.submenus.length > 0 && (

                        element.submenus.map((submenu, submenuIndex) => (
                            <div key={submenuIndex}>
                                <div className='con sub'>
                                    {submenu.menu}
                                </div>
                                {submenu.submenus && submenu.submenus.length > 0 && (

                                    submenu.submenus.map((submenuData, submenuIndexNumber) => (
                                        <div key={submenuIndexNumber} className="con sub_sub">
                                            {submenuData.menu}
                                        </div>

                                    ))

                                )}

                            </div>

                        ))

                    )}
                </div>

            ));

            column2 = userData.slice(i, i + 8).map((element, index) => element?.type != "footermenu" && (

                <div key={index}><div className="con">
                    {roleVal !== "" &&
                        toggleData?.map((toggle, toggleIndex) => {
                            const foundPermissions = props.userData && props.userData.find(rp => rp.menuid === element.menuid);
                            const founduserDataPermissions = props?.userData && props?.userData?.find(rp => rp.menuid === element.menuid);
                            const checkBool = (founduserDataPermissions && toggle.name in founduserDataPermissions) ? founduserDataPermissions[toggle.name] : foundPermissions?.[toggle.name];
                            return (
                                <div className="column" key={toggleIndex}>
                                    {element[toggle.name] && <label className="toggle" >
                                        <input id={`${trimString(element?.menu)}-${toggle.name}-${toggleIndex}`} className="toggle-checkbox"
                                            type="checkbox" checked={checkBool} onChange={(e) => element.menu != 'Pipeline' && handleToggle(e, element.menuid)} name={toggle.name + "," + element.menu + "," + roleVal + "," + checkBool} />
                                        <div className="toggle-switch"></div>

                                    </label>
                                    }
                                </div>
                            )
                        })
                    }
                </div>
                    {element.submenus && element.submenus.length > 0 && (
                        element.submenus.map((submenu, submenuIndex) => (
                            <div key={submenuIndex} >
                                <div key={submenuIndex} className="con">
                                    {roleVal !== "" &&
                                        toggleData?.map((toggle, toggleIndex) => {
                                            const founduserDataPermissions = props?.userData && props?.userData?.find(rp => rp.menuid === element.menuid);
                                            const founduserDatasubPermissions = founduserDataPermissions && founduserDataPermissions?.submenus && founduserDataPermissions?.submenus?.find(rp => rp.menu === submenu.menu);
                                            const checkBool = (founduserDatasubPermissions && toggle.name in founduserDatasubPermissions) ? founduserDatasubPermissions[toggle.name] : false;

                                            return (
                                                submenu[toggle.name] && <div className="column" key={toggleIndex}>
                                                    <label className="toggle" key={toggleIndex}>
                                                        <input id={`${trimString(submenu?.menu)}-${toggle.name}-${toggleIndex}`} className="toggle-checkbox"
                                                            type="checkbox" checked={checkBool} onChange={(e) => handlesubToggle(e, element.menuid, submenu.menu)} name={toggle.name + "," + submenu.menu + "," + roleVal + "," + checkBool} />
                                                        <div className="toggle-switch"></div>
                                                    </label>
                                                </div>
                                            )
                                        })
                                    }
                                </div>
                                {submenu.submenus && submenu.submenus.length > 0 && (
                                    submenu.submenus.map((submenuData, submenuIndexNumber) => (
                                        <div key={submenuIndexNumber} className="con">
                                            {roleVal !== "" &&
                                                toggleData?.map((toggle, toggleIndex) => {
                                                    const founduserDataPermissions = props?.userData && props?.userData?.find(rp => rp.menuid === element.menuid);
                                                    const founduserDatasubPermissions = founduserDataPermissions && founduserDataPermissions?.submenus && founduserDataPermissions?.submenus?.find(rp => rp.menu === submenu.menu);
                                                    const founduserDatasubPermissionsValue = founduserDatasubPermissions && founduserDatasubPermissions?.submenus && founduserDatasubPermissions?.submenus?.find(rp => rp.menu === submenuData.menu);
                                                    //  console.log("founduserDatasubPermissionsValue",founduserDatasubPermissionsValue)
                                                    const checkBool = (founduserDatasubPermissionsValue && toggle.name in founduserDatasubPermissionsValue) ? founduserDatasubPermissionsValue[toggle.name] : false;

                                                    return (
                                                        submenuData[toggle.name] && <div className="column" key={toggleIndex}>
                                                            <label className="toggle" key={toggleIndex}>
                                                                <input id={`${trimString(submenuData?.menu)}-${toggle.name}-${toggleIndex}`} className="toggle-checkbox"
                                                                    type="checkbox" checked={checkBool} onChange={(e) => handlesubToggle1(e, element.menuid, submenu.menu, submenuData.menu)} name={toggle.name + "," + submenuData.menu + "," + roleVal + "," + checkBool} />
                                                                <div className="toggle-switch"></div>
                                                            </label>
                                                        </div>
                                                    )
                                                })
                                            }
                                        </div>




                                    ))
                                )}

                            </div>




                        ))
                    )}
                </div>

            ));

            columns.push(
                <div className="col-md-12" key={i}>
                    <div className="check_group">
                        <div className="left">
                            <div className="hed"></div>
                            {column}
                            {/* <div className="con sub">Sub menu</div>
                                        <div className="con sub_sub">Sub sub menu</div> */}
                        </div>
                        <div className="right">
                            <div className="hed">
                                <div className="column">EDIT</div>
                                <div className="column">VIEW</div>
                                <div className="column">DELETE</div>
                            </div>
                            {column2}
                        </div>




                    </div>
                </div>


            );

        }




        return columns
    };

    const handleToggle = (e, menuid) => {
        console.log("toggle changed", e, menuid);
        setRenderState(!renderState);
        const delimiter = ",";
        const str = e.target.name;
        const toggleVal = str.split(delimiter);
        const toggName = toggleVal[0];
        // console.log(typeof(toggswitch));
        let tempData = JSON.parse(JSON.stringify(props.userData));
        tempData.map((data, dataIndex) => {
            if (data?.menuid === menuid) {
                let checkedValue = e.target.checked
                data[toggName] = e.target.checked;
                        if(toggName == "view" && checkedValue == false){
                            data["edit"] = checkedValue  
                        }
            }
        });

        props?.childUserData(tempData);
    }
    const handlesubToggle = (e, menuid, submenuId) => {
        setRenderState(!renderState);
        const delimiter = ",";
        const str = e.target.name;
        console.log("str", str)
        const toggleVal = str.split(delimiter);
        console.log("toggleVal", toggleVal)
        const toggName = toggleVal[0];
        const toggPerm = toggleVal[1];
        const toggRole = toggleVal[2];
        const toggSec = toggleVal[3];
        const toggswitch = toggleVal[4];
        let tempData = JSON.parse(JSON.stringify(props.userData));
        tempData = tempData.map((data, dataIndex) => {
            if (data?.menuid === menuid) {
                if (data?.submenus) {
                    const submenuIndex = data.submenus.findIndex(rp => rp.menu === submenuId);
                    // if (submenuIndex !== -1) {
                    //     let updatedSubmenu = { ...data.submenus[submenuIndex], [toggName]: e.target.checked };
                    //     if(toggName == "view"){
                    //         updatedSubmenu["edit"] = e.target.checked  
                    //     }
                    //     data.submenus[submenuIndex] = updatedSubmenu;
                    // }
                    if (submenuIndex !== -1) {
                        let checkedValue = e.target.checked
                        let updatedSubmenu = { ...data.submenus[submenuIndex], [toggName]: e.target.checked };
                        if(toggName == "view" && checkedValue == false){
                            updatedSubmenu["edit"] = checkedValue  
                        }
                        data.submenus[submenuIndex] = updatedSubmenu;
                    }
                }
            }
            return data;
        });
        console.log("tempData1", tempData)
        props?.childUserData(tempData);
    }

    const handlesubToggle1 = (e, menuid, submenuId, submenuName) => {
        setRenderState(!renderState);
        const delimiter = ",";
        const str = e.target.name;
        console.log("str", str)
        const toggleVal = str.split(delimiter);
        console.log("toggleVal", toggleVal, e.target.checked)
        const toggName = toggleVal[0];
        const toggPerm = toggleVal[1];
        const toggRole = toggleVal[2];
        const toggSec = toggleVal[3];
        const toggswitch = toggleVal[4];
        let tempData = JSON.parse(JSON.stringify(props.userData));
        tempData = tempData.map((data, dataIndex) => {
            if (data?.menuid === menuid) {
                if (data?.submenus) {
                    const subSubMenus = data.submenus.find(rp => rp.menu === submenuId);
                    const subSubMenusIndex = data.submenus.findIndex(rp => rp.menu === submenuId);
                    const submenuIndex = subSubMenus.submenus.findIndex(rp => rp.menu === submenuName);
                    // if (submenuIndex !== -1) {

                    //     let updatedSubmenu = { ...data.submenus[subSubMenusIndex].submenus[submenuIndex], 
                    //         [toggName]: e.target.checked };
                    //         if(toggName == "view"){
                    //             updatedSubmenu["edit"] = e.target.checked  
                    //         }
                    //     data.submenus[subSubMenusIndex].submenus[submenuIndex] = updatedSubmenu;
                    // }
                    if (submenuIndex !== -1) {
                        let checkedValue = e.target.checked
                        let updatedSubmenu = { ...data.submenus[subSubMenusIndex].submenus[submenuIndex], 
                            [toggName]: e.target.checked };
                        if(toggName == "view" && checkedValue == false){
                            updatedSubmenu["edit"] = checkedValue  
                        }
                            // if(toggName == "view"){
                            //     updatedSubmenu["edit"] = e.target.checked  
                            // }
                        data.submenus[subSubMenusIndex].submenus[submenuIndex] = updatedSubmenu;
                    }
                }
            }
            return data;
        });
        console.log("tempData", tempData)
        props?.childUserData(tempData);
    }
    const handleToggRoleEmpty = (e) => {
        if (roleVal === '') {
            setAlert(!alert);

        }
    }

    const handleAlertClose = () => {
        setAlert(!alert);
    }


    useEffect(() => {

        renderElements()

    }, [renderState])



    return (
        <div className="section">

            <div className='row'>
                <h3>{props?.roleName == 'accountexecutive' ? 'ACCOUNT EXECUTIVE' : props.roleName}</h3>
                {renderElements()}
            </div>
            {alert && <SweetAlert
                warning
                title="Select Role is Empty"
                onConfirm={handleAlertClose}
            >
                {/* Display your validation error message */}
                Please select role
            </SweetAlert>}
        </div>

    );
};

export default User;