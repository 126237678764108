import React, {useState, useEffect} from 'react';
import SideMenu from './SideMenu';
import MainHeader from './MainHeader';
import {adminHmdaReport , getSingleHmda, hmdaAdd , getAdminHmdaDetails} from "../utils/reducer";
import EditorEdit from './EditorEdit.js';
import { useLocation, useNavigate} from 'react-router-dom';
import { showLoader, hideLoader } from '../redux/reducers/Actions.js';
import { useDispatch, useSelector} from 'react-redux';
import PopupMessage from './PopupMessage';
import Loader from './Loader';
import HomeFooter from './HomeFooter';
import LocationSearch from './LocationsSeachBox';
import { useLoanInfo } from '../utils/loanInfoContext';
import { getUserDataFromLocalStorage } from '../utils/local';
import { hasViewPermission,hasEditPermission } from '../utils/permissionsCheck';

const AdminReportsHmda = () => {
    const navigate = useNavigate();
    const location = useLocation();
    console.log('location',location?.state);
    const loading = useSelector((state) => state.loaderVisible);
    const { loanInfo } = useLoanInfo();
    console.log("loanInfo",loanInfo);
    const configdata = useSelector(state => state.configdata);
    const mapsKey = configdata?.common?.googleMapsKey;
    const dispatch = useDispatch();
    const [isPopupOpen, setPopupOpen] = useState(false);
    const [message , setMessage] = useState('Updated Successfully');
    const [hmdaErrors, setHmdaErrors] = useState({});
    const [hmdaReportData,setHmdaReportData] = useState({});
    let userData = getUserDataFromLocalStorage();
    console.log('selectedEmail---->',userData);
    const [pipelinePermissions, setPipelinePermissions] = useState();
    const [editPermissions, setEditPermissions] = useState( );
    let hmdaVariable = {
        "loanandorganisationinfo":{
            "loanpurpose":"",
            "preapprovals":"",
            "actiontaken":"",
            "actiondate":"",
            "typeofpurchase":"",
            "rateofspreadpercentage":"",
            "hoepastatus":""
        },
        "reasonfordenial":{
            "reasonfordenial1": "",
            "reasonfordenial2": "",
            "reasonfordenial3": "",
            "reasonfordenial4": "",
            "otherreason": "",
        },
        "closingcostdetails":{
            "negativeamortization" : "",
            "othernonamortization" : "",
            "manusecuredproptype" : "",
            "manuhomelandpropintr" : "",
            "multifamilynounits" : "",
            "submissionofapp" : "",
            "initiallypayable" : "",
            "nmlsloanoriginatorid" : ""
        },
        "riskassessment" :{
            "aus1" : '',
            "aus2" : '',
            "aus3" : '',
            "aus4" : '',
            "aus5" : '',
            "otheraus" : '',
            "ausrecommendation1" : "",
            "ausrecommendation2" : "",
            "ausrecommendation3" : "",
            "ausrecommendation4" : "",
            "ausrecommendation5" : "",
            "otherausrecommendation" : "",
            "reversemortgage" : "",
            "openendlineofcredit" : "",
            "businesscommercial" : "",
        },
        "regulationb" : {
            "actiontaken" : "",
            "actiondate" : "",
        }
    };

    const [hmdaData , setHmdaData] = useState(hmdaVariable);
    const [hmdaCheck,setHmdaCheck] = useState(hmdaVariable);
    const [isloanOrganisationChange,setIsloanOrganisationChange] = useState(false);
    const [isReasonChange,setIsReasonChange] = useState(false);
    const [isClosingChange,setIsClosingChange] = useState(false);
    const [isRiskChange,setIsRiskChange] = useState(false);
    const [isRegulationChange,setIsRegulationChange] = useState(false);
    //const [show,setShow] = useState(false);
    const [errors,setErrors] = useState({});
    const [activeTab, setActiveTab] = useState("ENTITY"); // Default to first tab
    const [type,setType] = useState('Save');
    const [uuidNum,setUuidNum] = useState(null);

    let error = {};

   
    const reasonsForDenialOptions = [
        "Collateral",
        "Credit application incomplete",
        "Credit history",
        "Debt-to-income ratio",
        "Employment history",
        "Insufficient cash (downpayment, closing costs)",
        "Mortgage insurance denied",
        "Not applicable",
        "Other",
        "Unverifiable information"
    ];

    const ausOptions = [
        "Desktop Underwriter (DU)",
        "Guaranteed Underwriting System (GUS)",
        "Loan Prospector (LP)",
        "Not applicable",
        "Other",
        "Technology Open to Approved Lenders (TOTAL) Scorecard"
    ];
    
    const ausRecOptions = [
        "Accept",
        "Approve/Eligible",
        "Approve/Ineligible",
        "Caution",
        "Eligible",
        "Error",
        "Ineligible",
        "Incomplete",
        "Invalid",
        "Not applicable",
        "Other",
        "Out of Scope",
        "Refer",
        "Refer with Caution",
        "Refer/Eligible",
        "Refer/Ineligible",
        "Unable to Determine"
    ];

    // const handleChange = (e) => {
       
    //     let name = e.target.name;
    //     let value = e.target.value;
       
    //     setHmdaReportData((prev) => ({
    //         ...prev,
    //         [name]: value,
    //     }));
        
    // };
    useEffect(() => {
        
        getHmdaData();
      }, []);

      const getHmdaData = ()=>{
        dispatch(showLoader());
        getAdminHmdaDetails().then(function (response) {
        if (response?.result?.[0]) {
            console.log("get all HMDA api called",response?.result);
            const { _id, created, createdBy, uuid, appname,updated,modifiedBy, ...filteredData } = response.result[0];
    
            setType("Update");
            setHmdaData(filteredData); // Set data without the unwanted keys
            setUuidNum(uuid); // You can still use `uuid` if needed elsewhere
        }
        dispatch(hideLoader());
        }).catch((err) => {
          dispatch(hideLoader());
          console.error.bind("my account error", err);
        })
    }
    useEffect(() => {
        if(location.pathname){
            const route = location.pathname;
            const cleanRoute = route.slice(1);
            let permission = userData?.securityInfo?.permission
           
            let checkedPermission =  hasViewPermission(cleanRoute, permission) 
              console.log('viewPermission',location.pathname,checkedPermission); 
              setPipelinePermissions(checkedPermission) 
            //   if(!checkedPermission){
            //     navigate('/pipeline')
            //   }
            }

            if(location.pathname){
                const route = location.pathname;
                const cleanRoute = route.slice(1);
                let permission = userData?.securityInfo?.permission
               
                let editPermission =  hasEditPermission(cleanRoute, permission) 
                  console.log('editPermission',location.pathname,editPermission); 
                  setEditPermissions(editPermission) 
                //   if(!checkedPermission){
                //     navigate('/pipeline')
                //   }
                }
            //setViewStatus()
      }, []);

    useEffect(() => {
        formChangeCheck(hmdaData?.reasonfordenial,hmdaCheck?.reasonfordenial,setIsReasonChange);
        formChangeCheck(hmdaData?.loanandorganisationinfo,hmdaCheck?.loanandorganisationinfo,setIsloanOrganisationChange);
        formChangeCheck(hmdaData?.closingcostdetails,hmdaCheck?.closingcostdetails,setIsClosingChange);
        formChangeCheck(hmdaData?.riskassessment,hmdaCheck?.riskassessment,setIsRiskChange);
        formChangeCheck(hmdaData?.regulationb,hmdaCheck?.regulationb,setIsRegulationChange);
    }, [hmdaData, hmdaCheck]);

    const formChangeCheck = (formdata,formCheck,setIsCheck)=>{
        const isFormChanged =
            JSON.stringify(formdata) !== JSON.stringify(formCheck);
            setIsCheck(isFormChanged);
        console.log(isFormChanged);
    }

    // const handleChange = (section, e) => {

    //     let { name, value } = e.target;
    //     let updatedValue = value;
    //     if(section && name === 'rateofspreadpercentage'){
    //         //const parsedValue = parseFloat(value.replace(/[^.0-9]/g, ''));
    //         const regex = /^100(\.0{1,2})?$|^\d{0,2}(\.\d{1,2})?$/;
    //         if (regex.test(updatedValue)) {
    //             updatedValue = value; // Invalid input, return the original row
    //         }else{
    //             updatedValue = '';
    //         }
    //         //updatedValue = isNaN(parsedValue) ? '' : parsedValue;
    //         setHmdaData((prevData) => ({
    //             ...prevData,
    //             [section]: {
    //                 ...prevData[section],
    //                 [name]: updatedValue,
    //             },
    //         }));

    //     }else if(section  && name !== 'rateofspreadpercentage'){
    //         setHmdaData((prevData) => ({
    //             ...prevData,
    //             [section]: {
    //                 ...prevData[section],
    //                 [name]: updatedValue,
    //             },
    //         }));
    //     }else{
    //         setHmdaReportData((prev) => ({
    //             ...prev,
    //             [name]: value,
    //         }));
    //     }
    
        
    // };

    const handleChange = (section, e) => {
        let { name, value } = e.target;
    
        if (section) {
            if (name === 'rateofspreadpercentage') {
                // Restrict input to a valid number
                value = value.replace(/[^0-9.]/g, '');
    
                // Remove the third character if the value exceeds 100
                if (parseFloat(value) > 100) {
                    value = value.slice(0, -1); // Remove the last character
                }
    
                // Allow only up to 2 decimal places
                if (value.includes(".")) {
                    const [integer, decimal] = value.split(".");
                    if (decimal?.length > 2) {
                        value = `${integer}.${decimal.slice(0, 2)}`;
                    }
                }
    
                // Update state for section fields
                setHmdaData((prevData) => ({
                    ...prevData,
                    [section]: {
                        ...prevData[section],
                        [name]: value,
                    },
                }));
            } else {
                // Update state for other fields within the section
                setHmdaData((prevData) => ({
                    ...prevData,
                    [section]: {
                        ...prevData[section],
                        [name]: value,
                    },
                }));
            }
        } else {
            // Update state for non-section fields
            setHmdaReportData((prev) => ({
                ...prev,
                [name]: value,
            }));
        }
    };
    
    
    
    const handleBack = (e,param) => {
        e.preventDefault(); 
        // if(show){
        //     setShow(!show);
        // }else{
        //     navigate(-1);
        // }
        if(param === "GENERATEREPORT"){
                setActiveTab("REGULATION");
        }else if(param === "REGULATION"){
                setActiveTab("LOAN");
        }else if(param === "LOAN"){
            setActiveTab("PROPERTY");
        }else if(param === "PROPERTY"){
            setActiveTab("HMDA");
        }else if(param === "HMDA"){
            setActiveTab("ENTITY");
        }
        
    }

    const handleFocus = (e,param) => {
        let name = e.target.name;
       
        setHmdaErrors((prevErrors) => ({
            ...prevErrors,
            [name] : null
        }));
    
        
    }

    const handleGenerateValidation = () =>{
        const fieldsToCheck = {
            startdate : 'startdate',
            enddate : 'enddate',
            //fundid : 'fundid'
        };
        error = {};
        //const isWhitespace = /^<p>(&nbsp;|\s)*<br>\s*<\/p>$/;
        for (const [fieldName, fieldLabel] of Object.entries(fieldsToCheck)) {
            
            if (!hmdaReportData?.[fieldName]) {
                error[fieldName] = `Please Enter ${fieldLabel} `;
            }
            
            
        };

       
        setHmdaErrors(error);
        return Object.keys(error).length === 0
    }

    const handleGenerateReport = async (e) => {

        const valid = handleGenerateValidation();
        
        if(valid){

            // console.log('hmdaData --->',hmdaData);
            console.log('hmdaReportData --->',hmdaReportData);

            let updatedData = {
                ...hmdaData,
                ...hmdaReportData
            };

            // Remove 'regulationb' property
            delete updatedData.regulationb;

            // Convert 'rateofspreadpercentage' to float if it exists
            if (updatedData.loanandorganisationinfo?.rateofspreadpercentage) {
                updatedData.loanandorganisationinfo.rateofspreadpercentage = parseFloat(
                    updatedData.loanandorganisationinfo.rateofspreadpercentage
                );
            }

            // Convert 'multifamilynounits' to float if it exists
            if (updatedData.closingcostdetails?.multifamilynounits) {
                updatedData.closingcostdetails.multifamilynounits = parseFloat(
                    updatedData.closingcostdetails.multifamilynounits
                );
            }

            console.log('Updated HMDA Data:', updatedData);
            dispatch(showLoader());
    
            try {
                
                const response = await adminHmdaReport(hmdaReportData?.startdate,hmdaReportData?.enddate,updatedData,uuidNum);
        
                if (response?.statusCode === 200 && response?.result != "No Data Found") {
                    let filePath = response?.result;
                    //let filePath = 'easystreetcapitaldev/hmda/1732008509534.csv';
                    let source = `${configdata?.common?.resourcesCloudfront}${filePath}`;

                    // Extract file extension from filePath
                    const fileExtension = filePath.substring(filePath.lastIndexOf("."));
                    
                    // Fetch the file
                    const fileResponse = await fetch(source);
                    if (!fileResponse.ok) throw new Error("Network response was not ok: " + fileResponse.status);
        
                    // Convert the response to a blob
                    const blob = await fileResponse.blob();
                    const url = window.URL.createObjectURL(blob);
                    
                    // Create an anchor element
                    const link = document.createElement("a");
                    link.href = url;
        
                    // Set the download attribute to specify the file name
                    link.download = `HMDAReport${fileExtension}`; // Use the actual filename from the row
        
                    // Append to the document and trigger a click event
                    document.body.appendChild(link);
                    link.click();
        
                    // Clean up
                    document.body.removeChild(link);
                    window.URL.revokeObjectURL(url);
                }else{
                    setPopupOpen(true);
                    setMessage("No Data Found!");
                }
                let emptyObj = {
                    startdate : '',
                    enddate : ''
                };
                setHmdaReportData(emptyObj);
                getHmdaData();
            } catch (error) {
                console.error("Download failed:", error);
            } finally {
                dispatch(hideLoader());
            }
        }
        
    };

    const handleAddloanandorganisationinfo = ()=>{
        const fieldsToCheck = {
             loanpurpose:"loanpurpose", preapprovals:"preapprovals", actiontaken:"actiontaken", actiondate:"actiondate", 
             typeofpurchase:"typeofpurchase", rateofspreadpercentage:"rateofspreadpercentage", hoepastatus:"hoepastatus"}
            let fields = hmdaData?.loanandorganisationinfo;
            let name = 'loanandorganisationinfo'
            //setShow(!show);
            //handleAdd(name,fields,fieldsToCheck);
            setActiveTab("HMDA"); // Navigate to the next tab
           
    };
    const handleAddreasonfordenial = ()=>{
        const fieldsToCheck = { reasonfordenial1: "reasonfordenial1",reasonfordenial2: "reasonfordenial2", reasonfordenial3: "reasonfordenial3",
            reasonfordenial4: "reasonfordenial4", otherreason: "otherreason" }
            let fields = hmdaData?.reasonfordenial;
            let name = 'reasonfordenial'
            setActiveTab("PROPERTY"); // Navigate to the next tab
            //handleAdd(name,fields,fieldsToCheck);
    };
    const handleAddclosingcostdetails = ()=>{
        const fieldsToCheck = { negativeamortization: "negativeamortization",othernonamortization: "othernonamortization", manusecuredproptype: "manusecuredproptype",
            manuhomelandpropintr: "manuhomelandpropintr", multifamilynounits: "multifamilynounits", submissionofapp: "submissionofapp",  initiallypayable: "initiallypayable",
            nmlsloanoriginatorid: "nmlsloanoriginatorid"
        };
            let fields = hmdaData?.closingcostdetails;
            let name = 'closingcostdetails'
            setActiveTab("LOAN"); // Navigate to the next tab
            //handleAdd(name,fields,fieldsToCheck);
    };
    const handleAddriskassessment = ()=>{
        const fieldsToCheck = {aus1: "aus1",aus2: "aus2",aus3: "aus3",aus4: "aus4", aus5: "aus5", otheraus: "otheraus", ausrecommendation1: "ausrecommendation1",
            ausrecommendation2: "ausrecommendation2", ausrecommendation3: "ausrecommendation3",ausrecommendation4: "ausrecommendation4",ausrecommendation5: "ausrecommendation5",otherausrecommendation: "otherausrecommendation",
            reversemortgage: "reversemortgage", openendlineofcredit: "openendlineofcredit", businesscommercial: "businesscommercial"
        }
            let fields = hmdaData?.riskassessment;
            let name = 'riskassessment'
            //handleAdd(name,fields,fieldsToCheck);
            setActiveTab("REGULATION"); // Navigate to the next tab
    };
    const handleAddregulationb = () => {
        const fieldsToCheck = { "actiontaken": "actiontaken", "actiondate": "actiondate" };
        let fields = hmdaData?.regulationb;
        let name = 'regulationb';
    
        let handleAdd = (name, fields, fieldsToCheck) => {
            const errors = {};
            for (const [fieldName, fieldLabel] of Object.entries(fieldsToCheck)) {
                const fieldValue = fields[fieldName];
                if (!fieldValue) {
                    const errorFieldName = fieldName + "s"; 
                    errors[errorFieldName] = `${fieldLabel} is required.`;
                }
            }
            setErrors(errors);
            if (Object.keys(errors).length === 0) {
                handleAddHmda(name, fields);
            } else {
                console.log('errors ----> ', errors);
            }
        };
    
        //handleAdd(name, fields, fieldsToCheck);
        setActiveTab("GENERATEREPORT"); // Navigate to the next tab
    };

    const handleAddHmda =(name ,fields)=>{
        let apiData = {[name]: fields,loanid : loanInfo?.loanid}
        if(hmdaData?.hmdaid){
            apiData["hmdaid"] = hmdaData?.hmdaid
        }
        dispatch(showLoader());
        hmdaAdd(apiData).then(function (response) {
            if (response) {
               console.log('response?.result',response.result);
               getHmda();
               dispatch(hideLoader());
               setPopupOpen(true);
            }
            }).catch((err) => {
              console.error.bind("my account error", err);
              dispatch(hideLoader());
            })
    };

    const getHmda = ()=>{
        dispatch(showLoader());
        getSingleHmda(loanInfo?.loanid)
        .then(function(response) {
            if (response.result?.length !== 0) {
                console.log('hmda response', response);
                setHmdaData((prev) => ({
                    ...prev,
                    ...response.result[0], 
                }));
                setHmdaCheck((prev) => ({
                    ...prev,
                    ...response.result[0], 
                }));
                setErrors({})
                dispatch(hideLoader());
            }else{
                setHmdaData({
                    ...hmdaVariable,
                });
                setHmdaCheck({
                    ...hmdaVariable
                })
                setErrors({})
                dispatch(hideLoader());
            }
        })
        .catch((err) => {
            console.error("error", err); 
            dispatch(hideLoader());
        });
    };

    const handleAdd = (name,fields,fieldsToCheck)=>{
        // console.log(fields)
        // const errors = {};
        // for (const [fieldName, fieldLabel] of Object.entries(fieldsToCheck)) {
        //     const fieldValue = fields[fieldName];
        //     if (typeof fieldValue === 'string' && !fieldValue.trim()) {
        //         errors[fieldName] = `Please Enter ${fieldLabel} `;
        //     } else if (typeof fieldValue === 'number') {
        //         const stringValue = fieldValue.toString();
        //         if (!stringValue.trim()) {
        //             errors[fieldName] = `Please Enter a valid ${fieldLabel} `;
        //         }
        //     }
        // };
        // setErrors(errors);
        // if (Object.keys(errors).length === 0) {
        //     handleAddHmda(name,fields);
        // }else{
        //     console.log('errors ----> ',errors)
        // }
        handleAddHmda(name,fields);
    };

    const handleBackMain = (e) => {
        e.preventDefault(); 
        // if(show){
        //     setShow(!show);
        // }else{
        //     navigate(-1);
        // }
        navigate(-1);
        
    }

    const tabStyles = {
        cursor : 'pointer',
    }
    
    const closePopup = () => {
        setPopupOpen(false);
        navigate('/hmda');
    };

   

    return (
        <div id="layout-wrapper">
        {loading ? <Loader/> : null} 

        {isPopupOpen && (
        <PopupMessage
          type={message === "Sheet Name or Program Type already Exists." || message === "No Data Found!"
            ? 'warning' : 'success'}
          message={message}
          onClose={closePopup}
        />
      )}

           <MainHeader />
            <SideMenu />

            <div className="main-content">

            <div className="page-content user_management create_fundmanagement">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-12">
                            <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                                <h4 className="mb-sm-0 font-size-18">HMDA REPORTING</h4>
                                {/* <a href="#" id="navigateFundManagerBack" onClick={(e) => handleBackMain(e)} className="back_btn"><span className="material-icons icon"> arrow_back
                                    </span>BACK</a> */}
                            </div>
                            <div className="card detailed_app">
                                <div className="card-body my_account hmda_reporting">
                                    
                                    <div className='row' id="loanOrginationInfoTab">
                                        
                                        <div className="page-title-box d-sm-flex align-items-center justify-content-center">
                                            
                                            <div className="form_section">
                                            <h3 className="title mb-3 form-title">loan & origination info</h3>
                                                <div className="row">

                                                    <div className="col-md-3">
                                                        <div className="form-group">
                                                            <label>Loan Purpose</label>
                                                            <select id="loanPurposeHmda" className={`form-control ${errors?.loanpurpose && 'invalid-input'}`} onFocus={() => { setErrors({ ...errors, loanpurpose: null }) }} name='loanpurpose' value={hmdaData?.loanandorganisationinfo?.loanpurpose} onChange={(e) => handleChange('loanandorganisationinfo', e)}>
                                                                <option selected value="">Select</option>
                                                                <option value="Cash-out refinancing">Cash-out refinancing</option>
                                                                <option value="Home improvement">Home improvement</option>
                                                                <option value="Home purchase">Home purchase</option>
                                                                <option value="Not applicable">Not applicable</option>
                                                                <option value="Other purpose">Other purpose</option>
                                                                <option value="Refinancing">Refinancing</option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-3">
                                                        <div className="form-group">
                                                            <label>Pre approvals</label>
                                                            <select id="preApprovalsHmda" className={`form-control ${errors?.preapprovals && 'invalid-input'}`} onFocus={() => { setErrors({ ...errors, preapprovals: null }) }} name='preapprovals' value={hmdaData?.loanandorganisationinfo?.preapprovals} onChange={(e) => handleChange('loanandorganisationinfo', e)}>
                                                            <option selected value="">Select</option>
                                                                <option value="Preapproval requested">Pre approval requested</option>
                                                                <option value="Preapproval not requested">Pre approval not requested</option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-3">
                                                        <div className="form-group">
                                                            <label>Action Taken</label>
                                                            <select id="actionTakenHmda" className={`form-control ${errors?.actiontaken && 'invalid-input'}`} onFocus={() => { setErrors({ ...errors, actiontaken: null }) }} name='actiontaken' value={hmdaData?.loanandorganisationinfo?.actiontaken} onChange={(e) => handleChange('loanandorganisationinfo', e)}>
                                                                    <option selected value="">Select</option>
                                                                    <option value="Application approved but not accepted">Application approved but not accepted</option>
                                                                    <option value="Application denied">Application denied</option>
                                                                    <option value="Application withdrawn by applicant">Application withdrawn by applicant</option>
                                                                    <option value="File closed for incompleteness">File closed for incompleteness</option>
                                                                    <option value="Loan originated">Loan originated</option>
                                                                    <option value="Preapproval request approved but not accepted">Preapproval request approved but not accepted</option>
                                                                    <option value="Preapproval request denied">Preapproval request denied</option>
                                                                    <option value="Purchased loan">Purchased loan</option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-3">
                                                        <div className="form-group">
                                                            <label>Action Date</label>
                                                            <input id="actionDateHmda" type="date"  className={`form-control ${errors?.actiondate && 'invalid-input'}`} onFocus={() => { setErrors({ ...errors, actiondate: null }) }} placeholder="mm-dd-yyy" name='actiondate'  value={hmdaData?.loanandorganisationinfo?.actiondate ? ( new Date(hmdaData?.loanandorganisationinfo?.actiondate).toISOString().split('T')[0]) : ''}
                                                            onChange={(e) => handleChange('loanandorganisationinfo', e)}/>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-3">
                                                        <div className="form-group">
                                                            <label>Type of Purchaser</label>
                                                            <select id="purchaserHmda" className={`form-control ${errors?.typeofpurchase && 'invalid-input'}`} onFocus={() => { setErrors({ ...errors, typeofpurchase: null }) }} name='typeofpurchase' value={hmdaData?.loanandorganisationinfo?.typeofpurchase} onChange={(e) => handleChange('loanandorganisationinfo', e)}>
                                                                    <option selected value="">Select</option>
                                                                    <option value="Affiliate institution">Affiliate institution</option>
                                                                    <option value="Commercial bank, savings bank, or savings association">Commercial bank, savings bank, or savings association</option>
                                                                    <option value="Credit union, mortgage company, or finance company">Credit union, mortgage company, or finance company</option>
                                                                    <option value="Fannie Mae">Fannie Mae</option>
                                                                    <option value="Farmer Mac">Farmer Mac</option>
                                                                    <option value="Freddie Mac">Freddie Mac</option>
                                                                    <option value="Ginnie Mae">Ginnie Mae</option>
                                                                    <option value="Life insurance company">Life insurance company</option>
                                                                    <option value="Not applicable">Not applicable</option>
                                                                    <option value="Other type of purchaser">Other type of purchaser</option>
                                                                    <option value="Private securitizer">Private securitizer</option>
                                                            </select>
                                                        </div>
                                                    </div>

                                                    <div className="col-md-3">
                                                        <div className="form-group">
                                                            <label>Rate of Spread %</label>
                                                            <input id="rateSpreadHmda" type="number"  className={`form-control ${errors?.rateofspreadpercentage && 'invalid-input'}`} onFocus={() => { setErrors({ ...errors, rateofspreadpercentage: null }) }} name='rateofspreadpercentage' value={hmdaData?.loanandorganisationinfo?.rateofspreadpercentage} onChange={(e) => handleChange('loanandorganisationinfo', e)}
                                                                placeholder="Enter Rate of Spread" />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-3">
                                                        <div className="form-group">
                                                            <label>HOEPA Status</label>
                                                            <select id="HoepaStatusHmda" className={`form-control ${errors?.hoepastatus && 'invalid-input'}`} onFocus={() => { setErrors({ ...errors, hoepastatus: null }) }} name='hoepastatus' value={hmdaData?.loanandorganisationinfo?.hoepastatus} onChange={(e) => handleChange('loanandorganisationinfo', e)}>
                                                            <option selected value="">Select</option>
                                                            <option value="High-cost mortgag">High-cost mortgage</option>
                                                            <option  value="Not applicable">Not applicable</option>
                                                            <option value="Not a high-cost mortgage">Not a high-cost mortgage</option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                    {/* <div className="col-md-12">
                                                        <div className="form-group">
                                                            <button id="saveLoanOrigination" href="#" disabled={!isloanOrganisationChange}  onClick={(e) => {e.preventDefault();handleAddloanandorganisationinfo() }} style={{'opacity' : isloanOrganisationChange ? 1 : 0.6}} className="fs_btn mr_10">{type}</button>
                                                            <button  href="#" className="border_btn">Back</button>
                                                        </div>
                                                    </div> */}
                                                </div>
                                            
                                            </div>
                                        </div>
                                    </div>

                                    <div className='row' id="reasonForDenialTab">
                                        
                                        <div className="page-title-box d-sm-flex align-items-center justify-content-center">
                                            
                                            <div className="form_section">
                                            <h3 className="title mb-3 form-title">reasons for denial</h3>
                                                <div className="row">

                                                    <div className="col-md-3">
                                                        <div className="form-group">
                                                            <label>Reason for Denial #1</label>
                                                            <select id="reasonDeal1" className={`form-control ${errors?.reasonfordenial1 && 'invalid-input'}`} onFocus={() => { setErrors({ ...errors, reasonfordenial1: null }) }} name='reasonfordenial1' value={hmdaData?.reasonfordenial?.reasonfordenial1} onChange={(e) => handleChange('reasonfordenial', e)}>
                                                                <option selected value="">Select</option>
                                                                {reasonsForDenialOptions.map((option, index) => (
                                                                    <option key={index} value={option}>{option}</option>
                                                                ))}
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-3">
                                                        <div className="form-group">
                                                            <label>Reason for Denial #2</label>
                                                            <select id="reasonDeal2" className={`form-control ${errors?.reasonfordenial2 && 'invalid-input'}`} onFocus={() => { setErrors({ ...errors, reasonfordenial2: null }) }} name='reasonfordenial2' value={hmdaData?.reasonfordenial?.reasonfordenial2} onChange={(e) => handleChange('reasonfordenial', e)}>
                                                            <option selected value="">Select</option>
                                                                {reasonsForDenialOptions.map((option, index) => (
                                                                    <option key={index} value={option}>{option}</option>
                                                                ))}
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-3">
                                                        <div className="form-group">
                                                            <label>Reason for Denial #3</label>
                                                            <select  id="reasonDeal3" className={`form-control ${errors?.reasonfordenial3 && 'invalid-input'}`} onFocus={() => { setErrors({ ...errors, reasonfordenial3: null }) }} name='reasonfordenial3' value={hmdaData?.reasonfordenial?.reasonfordenial3} onChange={(e) => handleChange('reasonfordenial', e)}>
                                                            <option selected value="">Select</option>
                                                                {reasonsForDenialOptions.map((option, index) => (
                                                                    <option key={index} value={option}>{option}</option>
                                                                ))}
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-3">
                                                        <div className="form-group">
                                                            <label>Reason for Denial #4</label>
                                                            <select id="reasonDeal4" className={`form-control ${errors?.reasonfordenial4 && 'invalid-input'}`} onFocus={() => { setErrors({ ...errors, reasonfordenial4: null }) }} name='reasonfordenial4' value={hmdaData?.reasonfordenial?.reasonfordenial4} onChange={(e) => handleChange('reasonfordenial', e)}>
                                                            <option selected value="">Select</option>
                                                                {reasonsForDenialOptions.map((option, index) => (
                                                                    <option key={index} value={option}>{option}</option>
                                                                ))}
                                                            </select>
                                                        </div>
                                                    </div>

                                                    <div className="col-md-6">
                                                        <div className="form-group">
                                                            <label>Other Denial Reasons</label>
                                                            <input id="otherDenialReasons" type="text"  className={`form-control ${errors?.otherreason && 'invalid-input'}`} onFocus={() => { setErrors({ ...errors, otherreason: null }) }} name='otherreason' value={hmdaData?.reasonfordenial?.otherreason} onChange={(e) => handleChange('reasonfordenial', e)}
                                                                placeholder="Enter Other Denial Reasons" />
                                                        </div>
                                                    </div>

                                                    {/* <div className="col-md-12">
                                                        <div className="form-group">
                                                            <button id="saveReasonForDenial" disabled={!isReasonChange} onClick={(e) => {handleAddreasonfordenial() }} style={{'opacity' : isReasonChange ? 1 : 0.6}} className="fs_btn me-2">{type}</button>
                                                            <button type="button" className="border_btn" onClick={(e) => handleBack(e,"HMDA")}>Back</button>
                                                        </div>
                                                    </div> */}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='row' id="closingCostsDetailsTab">
                                        
                                        <div className="page-title-box d-sm-flex align-items-center justify-content-center">
                                            
                                            <div className="form_section">
                                            <h3 className="title mb-3 form-title">closingCostsDetails</h3>
                                                <div className="row">

                                                    <div className="col-md-3">
                                                        <div className="form-group">
                                                            <label>Negative Amortization</label>
                                                            <select  id="negativeAmortization" className={`form-control ${errors?.negativeamortization && 'invalid-input'}`} onFocus={() => { setErrors({ ...errors, negativeamortization: null }) }} name='negativeamortization' value={hmdaData?.closingcostdetails?.negativeamortization} onChange={(e) => handleChange('closingcostdetails', e)}>
                                                            <option selected value="">Select</option>
                                                            <option value="Negative amortization">Negative amortization</option>
                                                            <option  value="No negative amortization">No negative amortization</option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-3">
                                                        <div className="form-group">
                                                            <label>Other Non-Amortization</label>
                                                            <select  id="otherAmortization" className={`form-control ${errors?.othernonamortization && 'invalid-input'}`} onFocus={() => { setErrors({ ...errors, othernonamortization: null }) }} name='othernonamortization' value={hmdaData?.closingcostdetails?.othernonamortization} onChange={(e) => handleChange('closingcostdetails', e)}>
                                                            <option selected value="">Select</option>
                                                            <option value="Other non-fully amortizing features">Other non-fully amortizing features</option>
                                                            <option value="No other non-fully amortizing features">No other non-fully amortizing features</option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-3">
                                                        <div className="form-group">
                                                            <label>Manu. Secured Prop Type</label>
                                                            <select  id="securedPropType" className={`form-control ${errors?.manusecuredproptype && 'invalid-input'}`} onFocus={() => { setErrors({ ...errors, manusecuredproptype: null }) }} name='manusecuredproptype' value={hmdaData?.closingcostdetails?.manusecuredproptype} onChange={(e) => handleChange('closingcostdetails', e)}>
                                                            <option selected value="">Select</option>
                                                            <option value="Manufactured home and land">Manufactured home and land</option>
                                                            <option value="Manufactured home and not land">Manufactured home and not land</option>
                                                            <option value="Not applicable">Not applicable</option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-3">
                                                        <div className="form-group">
                                                            <label>Manu. Home Land Prop Intr</label>
                                                            <select  id="homeLandProp" className={`form-control ${errors?.manuhomelandpropintr && 'invalid-input'}`} onFocus={() => { setErrors({ ...errors, manuhomelandpropintr: null }) }} name='manuhomelandpropintr' value={hmdaData?.closingcostdetails?.manuhomelandpropintr} onChange={(e) => handleChange('closingcostdetails', e)}>
                                                            <option selected value="">Select</option>
                                                            <option value="Direct ownership">Direct ownership</option>
                                                            <option value="Indirect ownership">Indirect ownership</option>
                                                            <option value="Paid leasehold">Paid leasehold</option>
                                                            <option value="Unpaid leasehold">Unpaid leasehold</option>
                                                            <option value="Not applicable">Not applicable</option>
                                                            </select>
                                                        </div>
                                                    </div>

                                                    <div className="col-md-3">
                                                        <div className="form-group">
                                                            <label>Multifamily No Units</label>
                                                            <input id="multiFamilyNoUnits" type="text"  className={`form-control ${errors?.multifamilynounits && 'invalid-input'}`} onFocus={() => { setErrors({ ...errors, multifamilynounits: null }) }} name='multifamilynounits' value={hmdaData?.closingcostdetails?.multifamilynounits} onChange={(e) => handleChange('closingcostdetails', e)}
                                                                placeholder="Enter Multifamily No Units" />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-3">
                                                        <div className="form-group">
                                                            <label>Submission of App</label>
                                                            <select id="appSubmission" className={`form-control ${errors?.submissionofapp && 'invalid-input'}`} onFocus={() => { setErrors({ ...errors, submissionofapp: null }) }} name='submissionofapp' value={hmdaData?.closingcostdetails?.submissionofapp} onChange={(e) => handleChange('closingcostdetails', e)}>
                                                            <option selected value="">Select</option>
                                                            <option value="Submitted directly to your institution">Submitted directly to your institution</option>
                                                            <option value="Not submitted directly to your institution">Not submitted directly to your institution</option>
                                                            <option value="Not applicable">Not applicable</option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-3">
                                                        <div className="form-group">
                                                            <label>Initially Payable</label>
                                                            <select  id="initiallyPayable" className={`form-control ${errors?.initiallypayable && 'invalid-input'}`} onFocus={() => { setErrors({ ...errors, initiallypayable: null }) }} name='initiallypayable' value={hmdaData?.closingcostdetails?.initiallypayable} onChange={(e) => handleChange('closingcostdetails', e)}>
                                                            <option selected value="">Select</option>
                                                            <option  value="Initially payable to your institution">Initially payable to your institution</option>
                                                            <option value="Not initially payable to your institution">Not initially payable to your institution</option>
                                                            <option value="Not applicable">Not applicable</option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-3">
                                                        <div className="form-group">
                                                            <label>NMLS Loan Originator ID</label>
                                                            <input id="nmlsLoanOriginatorId" type="text"  className={`form-control ${errors?.nmlsloanoriginatorid && 'invalid-input'}`} onFocus={() => { setErrors({ ...errors, nmlsloanoriginatorid: null }) }} name='nmlsloanoriginatorid' value={hmdaData?.closingcostdetails?.nmlsloanoriginatorid} onChange={(e) => handleChange('closingcostdetails', e)}
                                                                placeholder="Enter NMLS Loan Originator ID" />
                                                        </div>
                                                    </div>

                                                    {/* <div className="col-md-12">
                                                        <div className="form-group">
                                                            <button id="saveClosingCosts" disabled={!isClosingChange} onClick={(e) => {handleAddclosingcostdetails() }} style={{'opacity' : isClosingChange ? 1 : 0.6}} className="fs_btn me-2">{type}</button>
                                                            <button type="button" className="border_btn" onClick={(e) => handleBack(e,"PROPERTY")}>Back</button>
                                                        </div>
                                                    </div> */}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='row' id="riskAssessmentTab">
                                        
                                        <div className="page-title-box d-sm-flex align-items-center justify-content-center">
                                            
                                            <div className="form_section">
                                            <h3 className="title mb-3 form-title">risk assessment</h3>
                                                <div className="row">

                                                    <div className="col-md-3">
                                                        <div className="form-group">
                                                            <label>AUS #1</label>
                                                            <select id="aus1" className={`form-control ${errors?.aus1 && 'invalid-input'}`} onFocus={() => { setErrors({ ...errors, aus1: null }) }} name='aus1' value={hmdaData?.riskassessment?.aus1} onChange={(e) => handleChange('riskassessment', e)}>
                                                                <option value='' selected>Select</option>
                                                                {ausOptions.map((option, index) => (
                                                                <option key={index} value={option}>{option}</option>
                                                            ))}
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-3">
                                                        <div className="form-group">
                                                            <label>AUS #2</label>
                                                            <select id="aus2" className={`form-control ${errors?.aus2 && 'invalid-input'}`} onFocus={() => { setErrors({ ...errors, aus2: null }) }}  name='aus2' value={hmdaData?.riskassessment?.aus2} onChange={(e) => handleChange('riskassessment', e)}>
                                                            <option value='' selected>Select</option>
                                                                {ausOptions.map((option, index) => (
                                                                <option key={index} value={option}>{option}</option>
                                                            ))}
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-3">
                                                        <div className="form-group">
                                                            <label>AUS #3</label>
                                                            <select id="aus3" className={`form-control ${errors?.aus3 && 'invalid-input'}`} onFocus={() => { setErrors({ ...errors, aus3: null }) }}  name='aus3' value={hmdaData?.riskassessment?.aus3} onChange={(e) => handleChange('riskassessment', e)}>
                                                            <option value='' selected>Select</option>
                                                                {ausOptions.map((option, index) => (
                                                                <option key={index} value={option}>{option}</option>
                                                            ))}
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-3">
                                                        <div className="form-group">
                                                            <label>AUS #4</label>
                                                            <select id="aus4" className={`form-control ${errors?.aus4 && 'invalid-input'}`} onFocus={() => { setErrors({ ...errors, aus4: null }) }}  name='aus4' value={hmdaData?.riskassessment?.aus4} onChange={(e) => handleChange('riskassessment', e)}>
                                                            <option value='' selected>Select</option>
                                                                {ausOptions.map((option, index) => (
                                                                <option key={index} value={option}>{option}</option>
                                                            ))}
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-3">
                                                        <div className="form-group">
                                                            <label>AUS #5</label>
                                                            <select id="aus5" className={`form-control ${errors?.aus5 && 'invalid-input'}`} onFocus={() => { setErrors({ ...errors, aus5: null }) }} name='aus5' value={hmdaData?.riskassessment?.aus5} onChange={(e) => handleChange('riskassessment', e)}>
                                                            <option value='' selected>Select</option>
                                                                {ausOptions.map((option, index) => (
                                                                <option key={index} value={option}>{option}</option>
                                                            ))}
                                                            </select>
                                                        </div>
                                                    </div>

                                                    <div className="col-md-3">
                                                        <div className="form-group">
                                                            <label>Other AUS(s)</label>
                                                            <input id="otherAus" type="text"  className={`form-control ${errors?.otheraus && 'invalid-input'}`} onFocus={() => { setErrors({ ...errors, otheraus: null }) }} name='otheraus' value={hmdaData?.riskassessment?.otheraus} onChange={(e) => handleChange('riskassessment', e)}
                                                                placeholder="Enter Other AUS" />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-3">
                                                        <div className="form-group">

                                                        </div>
                                                    </div>
                                                    <div className="col-md-3">
                                                        <div className="form-group">

                                                        </div>
                                                    </div>
                                                    <div className="col-md-3">
                                                        <div className="form-group">
                                                            <label>AUS Result #1</label>
                                                            <label>AUS Result #1</label>
                                                            <select id="ausRecommendation1" className={`form-control ${errors?.ausrecommendation1 && 'invalid-input'}`} onFocus={() => { setErrors({ ...errors, ausrecommendation1: null }) }} name='ausrecommendation1' value={hmdaData?.riskassessment?.ausrecommendation1} onChange={(e) => handleChange('riskassessment', e)}>
                                                                <option selected value=''>Select</option>
                                                                {ausRecOptions.map((option, index) => (
                                                                        <option key={index} value={option}>{option}</option>
                                                                    ))}
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-3">
                                                        <div className="form-group">
                                                            <label>AUS Result #2</label>
                                                            <label>AUS Result #2</label>
                                                            <select id="ausRecommendation2" className={`form-control ${errors?.ausrecommendation2 && 'invalid-input'}`} onFocus={() => { setErrors({ ...errors, ausrecommendation2: null }) }} name='ausrecommendation2' value={hmdaData?.riskassessment?.ausrecommendation2} onChange={(e) => handleChange('riskassessment', e)}>
                                                            <option selected value=''>Select</option>
                                                                {ausRecOptions.map((option, index) => (
                                                                        <option key={index} value={option}>{option}</option>
                                                                    ))}
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-3">
                                                        <div className="form-group">
                                                            <label>AUS Result #3</label>
                                                            <label>AUS Result #3</label>
                                                            <select id="ausRecommendation3" className={`form-control ${errors?.ausrecommendation3 && 'invalid-input'}`} onFocus={() => { setErrors({ ...errors, ausrecommendation3: null }) }} name='ausrecommendation3' value={hmdaData?.riskassessment?.ausrecommendation3} onChange={(e) => handleChange('riskassessment', e)}>
                                                            <option selected value=''>Select</option>
                                                                {ausRecOptions.map((option, index) => (
                                                                        <option key={index} value={option}>{option}</option>
                                                                    ))}
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-3">
                                                        <div className="form-group">
                                                            <label>AUS Result #4</label>
                                                            <label>AUS Result #4</label>
                                                            <select id="ausRecommendation4" className={`form-control ${errors?.ausrecommendation4 && 'invalid-input'}`} onFocus={() => { setErrors({ ...errors, ausrecommendation4: null }) }} name='ausrecommendation4' value={hmdaData?.riskassessment?.ausrecommendation4} onChange={(e) => handleChange('riskassessment', e)}>
                                                            <option selected value=''>Select</option>
                                                                {ausRecOptions.map((option, index) => (
                                                                        <option key={index} value={option}>{option}</option>
                                                                    ))}
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-3">
                                                        <div className="form-group">
                                                            <label>AUS Result #5</label>
                                                            <label>AUS Result #5</label>
                                                            <select id="ausRecommendation5" className={`form-control ${errors?.ausrecommendation5 && 'invalid-input'}`} onFocus={() => { setErrors({ ...errors, ausrecommendation5: null }) }} name='ausrecommendation5' value={hmdaData?.riskassessment?.ausrecommendation5} onChange={(e) => handleChange('riskassessment', e)}>
                                                            <option selected value=''>Select</option>
                                                                {ausRecOptions.map((option, index) => (
                                                                        <option key={index} value={option}>{option}</option>
                                                                    ))}
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-3">
                                                        <div className="form-group">
                                                            <label>Other AUS(s) Result</label>
                                                            <label>Other AUS(s) Result</label>
                                                            <input id="otherAusRecommendation" type="text"  className={`form-control ${errors?.otherausrecommendation && 'invalid-input'}`} onFocus={() => { setErrors({ ...errors, otherausrecommendation: null }) }} name='otherausrecommendation' value={hmdaData?.riskassessment?.otherausrecommendation} onChange={(e) => handleChange('riskassessment', e)}
                                                                placeholder="Enter Other AUS Recommendation" />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-3">
                                                        <div className="form-group">

                                                        </div>
                                                    </div>
                                                    <div className="col-md-3">
                                                        <div className="form-group">

                                                        </div>
                                                    </div>
                                                    <div className="col-md-3">
                                                        <div className="form-group">
                                                            <label>Reverse Mortgage</label>
                                                            <select id="reverseMortgage" className={`form-control ${errors?.reversemortgage && 'invalid-input'}`} onFocus={() => { setErrors({ ...errors, reversemortgage: null }) }} name='reversemortgage' value={hmdaData?.riskassessment?.reversemortgage} onChange={(e) => handleChange('riskassessment', e)}>
                                                                <option value="" selected>Select</option>
                                                                <option value="Reverse Mortgage">Reverse Mortgage</option>
                                                            <option selected="selected" value="Not a reverse mortgage">Not a reverse mortgage</option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-3">
                                                        <div className="form-group">
                                                            <label>Open-End Line of Credit</label>
                                                            <select id="creditOpenEndLine" className={`form-control ${errors?.openendlineofcredit && 'invalid-input'}`} onFocus={() => { setErrors({ ...errors, openendlineofcredit: null }) }} name='openendlineofcredit' value={hmdaData?.riskassessment?.openendlineofcredit} onChange={(e) => handleChange('riskassessment', e)}>
                                                            <option value="" selected>Select</option>
                                                            <option value="Open-end line of credit">Open-end line of credit</option>
                                                            <option  value="Not an open-end line of credit">Not an open-end line of credit</option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-3">
                                                        <div className="form-group">
                                                            <label>Business/Commercial</label>
                                                            <select id="businessOrCommercial" className={`form-control ${errors?.businesscommercial && 'invalid-input'}`} onFocus={() => { setErrors({ ...errors, businesscommercial: null }) }} name='businesscommercial' value={hmdaData?.riskassessment?.businesscommercial} onChange={(e) => handleChange('riskassessment', e)}>
                                                            <option value="" selected>Select</option>
                                                            <option value="Primarily for a business or commercial purpose">Primarily for a business or commercial purpose</option>
                                                            <option  value="Not primarily for a business or commercial purpose">Not primarily for a business or commercial purpose</option>

                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-3">
                                                        <div className="form-group">

                                                        </div>
                                                    </div>
                                                    {/* <div className="col-md-12">
                                                        <div className="form-group">
                                                            <button id="saveRiskAssessment" disabled={!isRiskChange} onClick={(e) => {handleAddriskassessment() }} style={{'opacity' : isRiskChange ? 1 : 0.6}} className="fs_btn me-2">{type}</button>
                                                            <button type="button" className="border_btn" onClick={(e) => handleBack(e,"LOAN")}>Back</button>
                                                        </div>
                                                    </div> */}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='row' id="generateReport">
                                    <div className="form_section">
                                            <h3 className="title mb-3 form-title">Generate Report</h3>
                                                <div className="row">
                                                    

                                                    <div className="col-md-3">
                                                        <div className="form-group">
                                                            <label>Select a Start date</label>
                                                            <input id="actionDateHmda" type="date" placeholder="mm-dd-yyy" name="startdate" tabindex="2" 
                                                                className={`form-control ${hmdaErrors?.startdate && 'invalid-input'}`}
                                                                onFocus={handleFocus}
                                                                onChange={(e) => handleChange('',e)} value={hmdaReportData?.startdate}
                                                                max={hmdaReportData?.enddate || undefined} // Disable dates after the selected end date
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-3">
                                                        <div className="form-group">
                                                            <label>Select a End date</label>
                                                            <input id="actionDateHmda" type="date" placeholder="mm-dd-yyy" name="enddate" tabindex="3" 
                                                                className={`form-control ${hmdaErrors?.enddate && 'invalid-input'}`}
                                                                onFocus={handleFocus}
                                                                onChange={(e) => handleChange('', e)} value={hmdaReportData?.enddate}
                                                                min={hmdaReportData?.startdate || undefined}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-3">
                                                        <div className="form-group">
                                                            <label>Legal Entity Identifier (LEI)  </label>
                                                            <input id="lei" type="text" name="lei" className={`form-control ${hmdaErrors?.lei && 'invalid-input'}`}
                                                                onFocus={handleFocus}
                                                                // onChange={(e) => handleChange(e)} 
                                                                value={"254900DRSIZBQXDCJ875"}
                                                                placeholder="Enter LEI" readOnly/>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-12">
                                                        <div className="form-group d-flex">
                                                            
                                                            <button id="generateReportBtn" type="button" className="create_btn me-2" onClick={handleGenerateReport}>Generate</button>
                                                            {/* <button id="generateReportBackBtn" type="button" className="border_btn" onClick={(e) => handleBack(e,"GENERATEREPORT")}>Back</button> */}
                                                            {/* <button id="generateReportBackBtn" type="button" className="border_btn" onClick={(e) => handleBack(e,"GENERATEREPORT")}>CANCEL</button> */}
                                                        </div>
                                                
                                                    </div>
                                                    
                                                    
                                                </div>
                                            </div>
                                    </div>
                                       
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>

               <HomeFooter />
            </div>

        </div>
    );
};

export default AdminReportsHmda;